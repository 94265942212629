import React from "react";
import { Box, Grid, Stack, Typography, CircularProgress } from "@mui/material";
import { Trans } from "@lingui/macro";
import _ from "lodash";
import { useLocalization } from "gatsby-theme-i18n";
import styled from "@emotion/styled";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import esgDocs from "../../cms/esgDocs/esgDocs";
import ArrowButton from "../../components/ArrowButton";
import Tabs from "../../components/Tabs";

function ReporstPage() {
  const { locale } = useLocalization();
  const [loaded, setLoaded] = React.useState(false);

  let content = _.groupBy(esgDocs[locale], "attributes.category");
  content = Object.entries(content).reduce((acc, [key, value]) => {
    acc[key] = _.groupBy(value, "attributes.publish_year");
    return acc;
  }, {});

  const currentYears = Object.keys(content.ESG_Annual_Report).reverse();
  const [selectedYear, setSelectedYear] = React.useState(currentYears[0]);

  const renderDoc = (key) => {
    const item = content[key][selectedYear]?.[0];

    return (
      item && (
        <Grid item xs={12} sm={6}>
          <Stack
            alignItems="end"
            direction={{ xs: "column", sm: "row" }}
          >
            {item.attributes.image.data ? (
              <StyledImage
                alt=""
                src={
                  item.attributes.image.data.attributes?.formats?.small?.url ||
                  item.attributes.image.data.attributes?.url
                }
                style={
                  loaded
                    ? { objectFit: "cover", objectPosition: "top" }
                    : { display: "none" }
                }
                onLoad={() => setLoaded(true)}
              />
            ) : (
              <Box
                sx={{
                  color: "white",
                  width: "200px",
                  height: "200px",
                  minWidth: "200px",
                  borderRadius: "5px",
                  textAlign: "center",
                  placeContent: "center",
                  display: loaded ? "grid" : "none",
                  backgroundColor: (theme) => theme.palette.primary.main,
                }}
              >
                <Typography color="white">
                  {item.attributes.category.replaceAll("_", " ")}
                </Typography>
              </Box>
            )}
            {loaded ? (
              <Stack
                width="100%"
                spacing={1}
                ml={{ xs: 0, sm: 2 }}
                mt={{ xs: 1, sm: 0 }}
                textAlign={{ xs: "center", sm: "start" }}
              >
                <Typography variant="h4">
                  {item.attributes.category.replaceAll("_", " ")}
                </Typography>
                <ArrowButton
                  title={<Trans>Download PDF</Trans>}
                  onClick={() =>
                    window.open(
                      item?.attributes.file.data.attributes.url,
                      "_blank",
                    )}
                  isDark
                />
              </Stack>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                sx={{ minHeight: "200px" }}
              >
                <CircularProgress />
              </Box>
            )}
          </Stack>
        </Grid>
      )
    );
  };

  return (
    <NewLayout byDefault apps="esg.reports">
      <Container pb={8}>
        <Typography mt={3} mb={1.5} variant="h2">
          <Trans>Reports</Trans>
        </Typography>
        <Typography>
          <Trans>
            Below you can find the latest ESG Reports issued by Allfunds of
            previous years
          </Trans>
        </Typography>
        <Box mt={2}>
          <Tabs
            value={selectedYear}
            tabs={currentYears}
            onChange={(_, value) => {
              setSelectedYear(value);
              setLoaded(false);
            }}
          />
          <Box mt={{ xs: 2, sm: 4 }}>
            <StyledGrid container spacing={{ xs: 4, sm: 2 }}>
              {Object.keys(content).map((key) => renderDoc(key))}
            </StyledGrid>
          </Box>
        </Box>
      </Container>
    </NewLayout>
  );
}

const StyledGrid = styled(Grid)`
  > div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

const StyledImage = styled.img`
  height: 200px;
  max-width: 200px;
  width: 100%;
  border-radius: 5px;
`;

export default ReporstPage;
